<template>
    <div>
        <card-header title="New Add-on" icon="fa-plus"/>

        <card-body>
            <card-list>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Trip Add-On" label-position="inside">
                                <b-select expanded v-model="addon">
                                    <option :value="a" v-for="a in tripAddons">{{ a.type | capitalize }} - {{ a.title }}</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
            </card-list>
            <addon-form :addon="newAddon" :template="addon" isnew/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" @click="submit" :disabled="!addon"><i class="fas fa-plus mr-3"></i>New Add-On</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';

    import {client as http} from '../../http_client';
    import {mapActions} from "vuex";
    import AddonForm from "./AddonForm";
    import CardList from "@/TIER/components/CardList";
    import ContainerListItem from "@/TIER/components/ContainerListItem";

    export default {
        props: ['card', 'props'],
        components: {ContainerListItem, CardList, AddonForm, CardFooter, CardBody, CardHeader},
        data: function() {
            return {
                newAddon: {
                    override_price: null,
                    override_price_double: null,
                    task_complete: 1
                },
                addon: null,
                tripAddons: [],
                registration: {}
            };
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadAddOns: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/addons/0/500', {force}).then(response => {
                    this.tripAddons = response.data.addons;

                    http.get('/api/registrations/' + this.props.registrationId, {force}).then(response => {
                        this.registration = response.data;
                        this.$emit('loading', false);
                    });
                });
            },
            submit() {
                this.$emit('loading', true);

                let payload = {...this.newAddon, addon_id: this.addon.id};

                http.post('/api/registrations/' + this.props.registrationId + '/addons', payload, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('addons', {registrationId: this.props.registrationId});
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        watch: {
            'addon': function(val) {

            }
        },
        mounted() {
            this.newAddon.registration_id = this.props.registrationId;
            this.$saveAlert(true, 'Are you done creating this new add-on? Unsaved changes will be lost.');
            this.loadAddOns();
        }
    };
</script>
